import { AppointmentCycleStatsType } from './AppointmentCycle'
import { ApptTypeProviderDepartmentIdInterface } from  './IdInterfaces'

export enum ProviderDepartmentOptionDataType {
    googlePlace = 'googlePlace',
    translation = 'translation',
    checkbox = 'checkbox',
    intNumber = 'intNumber',
    practiceSlug = 'practiceSlug',
    statsType = 'statsType'
}

export enum ProviderDepartmentOptionItem {
    showSuggestedArrivalTimeInVwr = 'showSuggestedArrivalTimeInVwr',
    showCheckInButtonInVwr = 'showCheckInButtonInVwr',
    patientSiteShowCallButton = 'patientSiteShowCallButton',
    patientSiteShowLocation = 'patientSiteShowLocation',
    patientSiteShowIntakeForm = 'patientSiteShowIntakeForm',
    patientSiteDirectionsTranslationId = 'patientSiteDirectionsTranslationId',
    patientSiteRemoteDirectionsTranslationId = 'patientSiteRemoteDirectionsTranslationId',
    patientSiteContactTranslationId = 'patientSiteContactTranslationId',
    patientSiteFaqTranslationId = 'patientSiteFaqTranslationId',
    patientSiteIntakeFormUrlTranslationId = 'patientSiteIntakeFormUrlTranslationId',
    patientSiteAboutVideoLinkTranslationId = 'patientSiteAboutVideoLinkTranslationId',
    anchorAutoPauseToSuggestedArrivalTime = 'anchorAutoPauseToSuggestedArrivalTime',
    suggestedArrivalTimeMaxDriftMinutes = 'suggestedArrivalTimeMaxDriftMinutes',
    patientSiteDisplayPaymentPortal = 'patientSiteDisplayPaymentPortal',
    patientSiteDisplayPatientPortal = 'patientSiteDisplayPatientPortal',
    appointmentsHideOnStatus = 'appointmentsHideOnStatus',
    appointmentsHideOnManager = 'appointmentsHideOnManager',
    appointmentsDisableDelayCalc = 'appointmentsDisableDelayCalc',
    patientSiteDisplayLatestSmsSuggestedArrivalTime = 'patientSiteDisplayLatestSmsSuggestedArrivalTime',
    patientSitePracticeSlugId = 'patientSitePracticeSlugId',
    autoPauseAfterMinutes = 'autoPauseAfterMinutes',
    forcePauseAfterMinutes = 'forcePauseAfterMinutes',
    minMinutesBetweenReminderTexts = 'minMinutesBetweenReminderTexts',
    googlePlaceId = 'googlePlaceId',
    useRenderingProvider = 'useRenderingProvider',
    statsType = 'statsType',
    persistCheckInToEhr = 'persistCheckInToEhr',
    useStatsV2 = 'useStatsV2',
    useMLDurations = 'useMLDurations',
    appointmentPlaceholderMessageTranslationId = 'appointmentPlaceholderMessageTranslationId',
    appointmentScheduledMessageTranslationId = 'appointmentScheduledMessageTranslationId',
    appointmentReminderMessageTranslationId = 'appointmentReminderMessageTranslationId',
    appointmentDelayedMessageTranslationId = 'appointmentDelayedMessageTranslationId',
    appointmentRoomReadyMessageTranslationId = 'appointmentRoomReadyMessageTranslationId',
    excludeFromViewsAndQueues = 'excludeFromViewsAndQueues',
    appointmentsAutoTextingDisabled = 'appointmentsAutoTextingDisabled',
    noSmsBeforeMinutesIntoDay = 'noSmsBeforeMinutesIntoDay',
    pollPatientChartsOnWaitForIntakeVitals = 'pollPatientChartsOnWaitForIntakeVitals',
    pollPatientChartsOnWaitForIntakeAllergies = 'pollPatientChartsOnWaitForIntakeAllergies',
    pollPatientChartsOnWaitForIntakeProblems = 'pollPatientChartsOnWaitForIntakeProblems',
    pollPatientChartsOnWaitForIntakeEncounterReasons = 'pollPatientChartsOnWaitForIntakeEncounterReasons',
    pollPatientChartsOnWaitForDoctorHpi = 'pollPatientChartsOnWaitForDoctorHpi',
    pollPatientChartsOnWaitForDoctorOrders = 'pollPatientChartsOnWaitForDoctorOrders',
    pollPatientChartsOnWaitForDoctorEncounterReasons = 'pollPatientChartsOnWaitForDoctorEncounterReasons',
    pollPatientChartsOnWaitForDoctorReviewOfSystem = 'pollPatientChartsOnWaitForDoctorReviewOfSystem',
    pollPatientChartsOnExamDiagnosis = 'pollPatientChartsOnExamDiagnosis',
    canRollbackStatus = 'canRollbackStatus',
    useBetaOverbookedStackingBehavior = 'useBetaOverbookedStackingBehavior',
    useStatusInferenceByLocation = 'useStatusInferenceByLocation'
}

export const providerDepartmentOptionDefaults = {
    [ProviderDepartmentOptionItem.showSuggestedArrivalTimeInVwr]: false,
    [ProviderDepartmentOptionItem.showCheckInButtonInVwr]: true,
    [ProviderDepartmentOptionItem.patientSiteShowCallButton]: true,
    [ProviderDepartmentOptionItem.patientSiteShowLocation]: true,
    [ProviderDepartmentOptionItem.patientSiteShowIntakeForm]: true,
    [ProviderDepartmentOptionItem.anchorAutoPauseToSuggestedArrivalTime]: null,
    [ProviderDepartmentOptionItem.suggestedArrivalTimeMaxDriftMinutes]: null,
    [ProviderDepartmentOptionItem.patientSiteDisplayPaymentPortal]: null,
    [ProviderDepartmentOptionItem.patientSiteDisplayPatientPortal]: null,
    [ProviderDepartmentOptionItem.appointmentsHideOnStatus]: null,
    [ProviderDepartmentOptionItem.appointmentsHideOnManager]: null,
    [ProviderDepartmentOptionItem.appointmentsDisableDelayCalc]: null,
    [ProviderDepartmentOptionItem.patientSiteDisplayLatestSmsSuggestedArrivalTime]: null,
    [ProviderDepartmentOptionItem.patientSitePracticeSlugId]: null,
    [ProviderDepartmentOptionItem.autoPauseAfterMinutes]: null,
    [ProviderDepartmentOptionItem.forcePauseAfterMinutes]: null,
    [ProviderDepartmentOptionItem.minMinutesBetweenReminderTexts]: null,
    [ProviderDepartmentOptionItem.useRenderingProvider]: null,
    [ProviderDepartmentOptionItem.statsType]: AppointmentCycleStatsType.PRACTICE,
    [ProviderDepartmentOptionItem.appointmentsAutoTextingDisabled]: false,
    [ProviderDepartmentOptionItem.noSmsBeforeMinutesIntoDay]: null,
    [ProviderDepartmentOptionItem.pollPatientChartsOnWaitForIntakeVitals]: false,
    [ProviderDepartmentOptionItem.pollPatientChartsOnWaitForIntakeAllergies]: false,
    [ProviderDepartmentOptionItem.pollPatientChartsOnWaitForIntakeEncounterReasons]: false,
    [ProviderDepartmentOptionItem.pollPatientChartsOnWaitForIntakeProblems]: false,
    [ProviderDepartmentOptionItem.pollPatientChartsOnWaitForDoctorHpi]: false,
    [ProviderDepartmentOptionItem.pollPatientChartsOnWaitForDoctorOrders]: false,
    [ProviderDepartmentOptionItem.pollPatientChartsOnWaitForDoctorEncounterReasons]: false,
    [ProviderDepartmentOptionItem.pollPatientChartsOnWaitForDoctorReviewOfSystem]: false,
    [ProviderDepartmentOptionItem.pollPatientChartsOnExamDiagnosis]: false,
    [ProviderDepartmentOptionItem.canRollbackStatus]: false,
    [ProviderDepartmentOptionItem.useBetaOverbookedStackingBehavior]: false,
    [ProviderDepartmentOptionItem.useStatusInferenceByLocation] : false,
}


export type ProviderDepartmentOptionIdObject = {
    providerDepartmentOptionId?: number
    practiceId: string
    providerId?: string
    departmentId?: string
    appointmentTypeId?: string
}

export const makeOptionPriorityByIds: (
    opt: ApptTypeProviderDepartmentIdInterface
) => number = ({ providerId, departmentId, appointmentTypeId, practiceId }) => {
    if (providerId && departmentId && appointmentTypeId) return 100
    if (!providerId && !departmentId && !appointmentTypeId && practiceId)
        return 10
    if (!providerId && !departmentId) return 90
    if (!providerId && !appointmentTypeId) return 80
    if (!providerId) return 70
    if (!departmentId && !appointmentTypeId) return 60
    if (!departmentId) return 50
    if (!providerId) return 40
    return -1
}

export enum PDOCategories {
    locationAndDirections = 'location-and-directions',
    contact = 'contact',
    faqs = 'faqs',
    forms = 'forms',
    payment = 'payment',
    checkin = 'checkin',
    links = 'links',
    none = 'none',
    remote = 'remote',
    translation = 'translation',
    googlePlaces = 'google-places',
    suggestedArrival = 'suggested-arrival',
    pausing = 'pausing',
    visibility = 'visibility',
    manager = 'manager',
    patient = 'patient',
    receptionStatus = 'reception-status',
    calculations = 'calculations',
    messaging = 'messaging'
}