import { string } from "yup"

export enum AppointmentCycleConfigConfig {
    ESTIMATED = 'ESTIMATED',
    SKIP = 'SKIP',
    DEFAULT = 'DEFAULT',
}

export enum AppointmentCycleConfigStep {
    CHECK_IN = 'CHECK_IN',
    WAIT_FOR_INTAKE = 'WAIT_FOR_INTAKE',
    INTAKE = 'INTAKE',
    WAIT_FOR_EXAM = 'WAIT_FOR_EXAM',
    EXAM = 'EXAM',
    CHECK_OUT = 'CHECKOUT',
    WAIT_FOR_CHECK_OUT = 'WAIT_FOR_CHECKOUT',
}

export enum AppointmentCycleStatsType {
    PRACTICE = 'PRACTICE',
    PROVIDER = 'PROVIDER',
}

export enum AppointmentCycleCombinedStatsType {
    ESTIMATED = 'ESTIMATED',
    PRACTICE = 'PRACTICE',
    PROVIDER = 'PROVIDER',
    DEFAULT = 'DEFAULT',
    SKIP = 'SKIP'
}

export interface AppointmentCycleConfigStepData {
    step: string
    config: string
    stats_type: string
    practice_id: string
    provider_id: string
    adjust_by_max: boolean
    department_id: number
    estimated_max: number
    default_duration: number
    appointment_type_id: number
    adjust_by_percentage: boolean
    estimated_percentage: number
}